/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @module MessageItemsModule
 */

import { NgModule } from '@angular/core';

import {
    createNgDependencyProviders,
} from 'ng/modules/data-model/utils/inject-ng-dependencies.utils';

import {
    ClusterNodeConfigItem,
    ControllerInterfaceConfigItem,
    CSRFRuleConfigItem,
    DNSConfigConfigItem,
    FailActionConfigItem,
    FailActionHttpLocalResponseConfigItem,
    FailActionHttpRedirectConfigItem,
    GslbClientIpAddrGroupConfigItem,
    GslbGeoLocationConfigItem,
    GslbIpAddrConfigItem,
    GslbPoolConfigItem,
    GslbPoolMemberConfigItem,
    GslbServiceDownResponseConfigItem,
    GslbSiteConfigItem,
    GslbSiteDnsVsConfigItem,
    GslbThirdPartySiteConfigItem,
    HTTPReselectRespCodeConfigItem,
    HTTPServerReselectConfigItem,
    HTTPStatusRangeConfigItem,
    PlacementNetworkConfigItem,
    PoolGroupMemberConfigItem,
    ServerConfigItem,
    StaticRouteConfigItem,
    VlanInterfaceConfigItem,
    VNICConfigItem,
    VNICNetworksConfigItem,
} from './factories';

const providers = createNgDependencyProviders([
    [CSRFRuleConfigItem, 'CSRFRuleConfigItem'],
    [FailActionConfigItem, 'FailActionConfigItem'],
    [FailActionHttpLocalResponseConfigItem, 'FailActionHTTPLocalResponseConfigItem'],
    [FailActionHttpRedirectConfigItem, 'FailActionHTTPRedirectConfigItem'],
    [HTTPReselectRespCodeConfigItem, 'HTTPReselectRespCodeConfigItem'],
    [HTTPServerReselectConfigItem, 'HTTPServerReselectConfigItem'],
    [HTTPStatusRangeConfigItem, 'HTTPStatusRangeConfigItem'],
    [PlacementNetworkConfigItem, 'PlacementNetworkConfigItem'],
    [ServerConfigItem, 'ServerConfigItem'],
    [PoolGroupMemberConfigItem, 'PoolGroupMemberConfigItem'],
    [VNICConfigItem, 'vNICConfigItem'],
    [VNICNetworksConfigItem, 'VNICNetworksConfigItem'],
    [VlanInterfaceConfigItem, 'VlanInterfaceConfigItem'],
    [ClusterNodeConfigItem, 'ClusterNodeConfigItem'],
    [StaticRouteConfigItem, 'StaticRouteConfigItem'],
    [ControllerInterfaceConfigItem, 'ControllerInterfaceConfigItem'],
    [GslbThirdPartySiteConfigItem, 'GslbThirdPartySiteConfigItem'],
    [GslbSiteConfigItem, 'GslbSiteConfigItem'],
    [GslbIpAddrConfigItem, 'GslbIpAddrConfigItem'],
    [GslbPoolConfigItem, 'GslbPoolConfigItem'],
    [GslbPoolMemberConfigItem, 'GslbPoolMemberConfigItem'],
    [GslbGeoLocationConfigItem, 'GslbGeoLocationConfigItem'],
    [GslbClientIpAddrGroupConfigItem, 'GslbClientIpAddrGroupConfigItem'],
    [GslbSiteDnsVsConfigItem, 'GslbSiteDnsVsConfigItem'],
    [DNSConfigConfigItem, 'DNSConfigConfigItem'],
    [GslbServiceDownResponseConfigItem, 'GslbServiceDownResponseConfigItem'],
]);

/**
 * @description
 * Module for registering MessageItem files. MessageItems can be imported by the 'message-items/*'
 * path which points to 'message-items/factories'. This module should contain providers only.
 *
 * MessageItems need to be registered with a string token, ex. 'ServerConfigItem' for the
 * ServerConfigItem class constructor. This is because MessageMapService injects messageItems by
 * string injection, where the string is dynamically generated based on the protobuf objectType.
 *
 * Note: Make sure that the string token matches the message (including casing) as defined in the
 * protobuf. For example, the class FailActionHttpRedirectConfigItem has camelcasing for 'Http' but
 * the message is 'FailActionHTTPRedirectConfigItem' with 'HTTP'.
 * @author alextsg
 */
@NgModule({
    providers,
})
export class MessageItemsModule {}
