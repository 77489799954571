/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/** @module GslbModule */

import {
    IHttpResponse,
    IPromise,
} from 'angular';

import { SystemInfoService } from 'ajs/modules/core/services';

import {
    AviPermissionResource,
    GslbServiceDownResponseType,
    IGslbPool,
    IGslbPoolMember,
    IGslbService,
    IIpAddr,
} from 'generated-types';

import {
    withEditChildMessageItemMixin,
} from 'ajs/modules/data-model/mixins/with-edit-child-message-item.mixin';
import {
    GslbServiceModalComponent,
} from 'ng/modules/gslb/components/gslb-service-modal/gslb-service-modal.component';
import { withFullModalMixin } from 'ajs/js/utilities/mixins/with-full-modal.mixin';
import {
    RepeatedMessageItem,
} from 'ajs/modules/data-model/factories/repeated-message-item.factory';
import { ObjectTypeItem } from 'ajs/modules/data-model/factories/object-type-item.factory';
import { GslbService as GslbServiceObjectType } from 'object-types';
import { L10nService } from '@vmw/ngx-vip';
import { fqdnSplit } from 'ng/shared/utils/fqdn-split.utils';
import { GSLB } from 'items/gslb.item.factory';
import {
    GslbPoolConfigItem,
    GslbPoolMemberConfigItem,
    GslbServiceDownResponseConfigItem,
} from 'ng/modules/message-items/factories';
import { GSLBServiceCollection } from './gslb-service.collection.factory';
import * as l10n from '../../gslb.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

type TGslbServicePartial = Omit<IGslbService, 'down_response' | 'groups'>;

interface IGslbServiceArgs {
    gslb?: GSLB;
}

export interface IGslbServiceConfig extends TGslbServicePartial {
    down_response?: GslbServiceDownResponseConfigItem,
    groups?: RepeatedMessageItem<GslbPoolConfigItem>,
}

interface IGslbServiceData {
    config: IGslbServiceConfig;
}

/**
 * Interface for Domain Name after split.
 */
export interface ISplitDomainName {
    appDomainName: string;
    subdomain: string;
}

/**
 * Repeated string properties, used for checking properties configuration on load and save.
 */
const REPEATED_STRINGS_PROPERTIES = ['domain_names', 'health_monitor_refs'];

/**
 * Constant for GSLB service pools field name.
 */
const GROUPS = 'groups';

/**
 * @description
 *     Item as of protobuf GslbService message. Needs a reference to GSLB it belongs to.
 * @author Alex Malitsky, Zhiqian Liu, Suraj Kumar
 */
export class GSLBService extends
    withEditChildMessageItemMixin(withFullModalMixin(ObjectTypeItem)) {
    public static ajsDependencies = [
        'GSLBVSCollection',
        'systemInfoService',
        'fqdnJoin',
        'stringService',
        'l10nService',
    ];

    public getConfig: () => IGslbServiceConfig;
    public getDefaultConfig_: () => IGslbService;
    public readonly gslb?: GSLB;
    public data: IGslbServiceData;
    protected collection: GSLBServiceCollection;
    protected opener: GSLBService;
    private readonly systemInfo: SystemInfoService;
    private readonly l10nService: L10nService;

    constructor(args = {}) {
        const extendedArgs = {
            objectName: 'gslbservice',
            objectType: GslbServiceObjectType,
            windowElement: GslbServiceModalComponent,
            permissionName: AviPermissionResource.PERMISSION_GSLBSERVICE,
            ...args,
        };

        super(extendedArgs);

        if ('gslb' in args) {
            const { gslb } = args as IGslbServiceArgs;

            this.gslb = gslb;
        }

        this.systemInfo = this.getAjsDependency_('systemInfoService');
        this.l10nService = this.getAjsDependency_('l10nService');

        this.l10nService.registerSourceBundles(dictionary);
    }

    /**
     * Since a GSLB pool member doesn't have a unique identifier field, we locate one with its
     * parent pool name and its IP address.
     * This method is made static since it's also used by GSLB pool member data transformer to build
     * data from API response.
     */
    public static getPoolMember(
        config: IGslbService,
        poolName: string,
        ip: IIpAddr,
    ): IGslbPoolMember | undefined {
        const { groups: gslbPools } = config;
        const targetPool: IGslbPool = gslbPools.find(
            ({ name }: IGslbPool) => name === poolName,
        );

        if (!targetPool) {
            return undefined;
        }

        const isIPEqual = ({ addr: addr1 }: IIpAddr, { addr: addr2 }: IIpAddr): boolean => {
            return addr1 === addr2;
        };

        return targetPool.members.find(
            ({ ip: memberIp }: IGslbPoolMember) => isIPEqual(memberIp, ip),
        );
    }

    /** @override */
    public isEditable(): boolean {
        const gslb = this.getGSLB();
        const isGslbLoaded = gslb?.hasConfig();

        if (super.isEditable() && isGslbLoaded) {
            // Edit is allowed for leaders by default
            // Incase of followers, edit is allowed if `enableConfigByMembers` prop is true.
            return this.systemInfo.localSiteIsGSLBLeader || gslb.enableConfigByMembers;
        }

        return false;
    }

    /**
     * Check the write permission of PERMISSION_GSLBSERVICE to modify `enabled` field of a pool
     * member.
     */
    public isPoolMemberEnableWritable(): boolean {
        // this.isEdiable() is an override with check on GSLB item existence and in turns it checks
        // on W/R permission of GSLB, so isEditable() from Item class is used
        return super.isEditable();
    }

    /** @override */
    public isProtected(): boolean {
        return !this.systemInfo.localSiteIsGSLBLeader || super.isProtected();
    }

    /** @override */
    public loadRequest(fields: string[]): IPromise<any> {
        const requests = [
            this.loadConfig(),
            this.loadMetrics(fields),
        ];

        return Promise.all(requests);
    }

    /**
     * Get method for GSLB instance this GSLBService belongs to.
     * //TODO mb it is better to pass gslb ref into GslbService item on create/edit/append
     */
    public getGSLB(): GSLB {
        if ('gslb' in this) {
            return this.gslb;
        } else if (this.collection) {
            return this.collection.gslb;
        } else if (this.opener && 'gslb' in this.opener) {
            return this.opener.gslb;
        }
    }

    /**
     * Checks whether Item is in enabled (default) state.
     */
    public isEnabled(): boolean {
        const { enabled } = this.getConfig();

        return Boolean(enabled);
    }

    /**
     * Makes a PATCH request to set enabled state to the passed value.
     */
    public setEnabledState(enabled: boolean): IPromise<IHttpResponse<void>> {
        return this.patch({ replace: { enabled } });
    }

    /** @override */
    public beforeEdit(): void {
        const config = this.getConfig();

        REPEATED_STRINGS_PROPERTIES.forEach((fieldName: string) => {
            if (!(fieldName in config)) {
                config[fieldName] = [];
            }
        });
    }

    /** @override */
    public dataToSave(): IGslbService {
        const config = super.dataToSave();

        REPEATED_STRINGS_PROPERTIES.forEach((fieldName: string) => {
            if (!config[fieldName].length) {
                config[fieldName] = undefined;
            }
        });

        return config;
    }

    /**
     * Open modal to add Pool.
     */
    public addPool(): void {
        this.addChildMessageItem({
            field: GROUPS,
            modalBindings: {
                editMode: false,
                poolNames: this.getPoolNames(),
                poolIndex: this.groups.count,
                gslbService: this,
            },
        });
    }

    /**
     * Open modal to edit Pool.
     */
    public editPool(gslbPool: GslbPoolConfigItem): void {
        this.editChildMessageItem({
            field: GROUPS,
            messageItem: gslbPool,
            modalBindings: {
                editMode: true,
                poolNames: this.getPoolNames(),
                poolIndex: this.groups.getArrayIndexWithMessageItem(gslbPool),
                gslbService: this,
            },
        });
    }

    /**
     * Remove Pool.
     */
    public deletePool(gslbPool: GslbPoolConfigItem): void {
        const { groups: poolList } = this.config;

        poolList.removeByMessageItem(gslbPool);
    }

    /**
     * Return configured Pools.
     */
    public get groups(): RepeatedMessageItem<GslbPoolConfigItem> {
        return this.config.groups;
    }

    /**
     * Return configured Pool names.
     */
    public getPoolNames(): string[] {
        return this.groups.getConfig()
            .map((pool: GslbPoolConfigItem) => pool.getConfig().name) || [];
    }

    /**
     * Set the 'enabled' field of a GSLB pool member with a new value.
     */
    public setPoolMemberEnableState(
        poolName: string,
        poolMemberIp: IIpAddr,
        newState: boolean,
    ): void {
        const poolMember = this.getPoolMemberConfig(poolName, poolMemberIp);

        if (poolMember) {
            poolMember.config.enabled = newState;
        }
    }

    /**
     * Return the GSLB Pool member config item.
     * Since a GSLB pool member doesn't have a unique identifier field, we locate one with its
     * parent pool name and its IP address.
     */
    public getPoolMemberConfig(
        poolName: string,
        ip: IIpAddr,
    ): GslbPoolMemberConfigItem | undefined {
        const { groups: gslbPools } = this.config;

        const targetPool: GslbPoolConfigItem = gslbPools.config.find(
            (pool: GslbPoolConfigItem) => pool.config.name === poolName,
        );

        if (!targetPool) {
            return undefined;
        }

        const isIPEqual = ({ addr: addr1 }: IIpAddr, { addr: addr2 }: IIpAddr): boolean => {
            return addr1 === addr2;
        };

        return targetPool.config.members.config.find(
            (member: GslbPoolMemberConfigItem) => isIPEqual(member.config.ip.config, ip),
        );
    }

    /**
     * Return array of domain names used by this GSLBService.
     */
    public getDomainNames(): string[] {
        const domainNames = this.getConfig().domain_names;

        return domainNames.concat();
    }

    /**
     * Update domain names, create list of domain names from split domain names.
     */
    public updateDomainNames(splitDomainNames: ISplitDomainName[] = []): void {
        this.config.domain_names = splitDomainNames.map((
            {
                appDomainName = '',
                subdomain = '',
            }: ISplitDomainName,
        ) => `${appDomainName}${subdomain}`);
    }

    /**
     * Since we present domain in forms as two inputs we need a way to split them on load.
     */
    public splitDomainNameField(fullDomainName: string): string[] {
        const gslb = this.getGSLB();

        return fqdnSplit(fullDomainName, gslb?.getDNSDomainNames());
    }

    /**
     * Delete application_persistence_profile_ref and pki_profile_ref.
     */
    public deleteSitePersistenceConfig(): void {
        delete this.config.application_persistence_profile_ref;
        delete this.config.pki_profile_ref;
    }

    /**
     * Return true if the type matches the configured down response type.
     */
    public isDownResponseType(type: GslbServiceDownResponseType): boolean {
        return this.config.down_response.isDownResponseType(type);
    }

    /** @override */
    protected getModalBreadcrumbTitle(): string {
        return this.l10nService.getMessage(l10nKeys.gslbServiceModalBreadcrumbTitle);
    }

    /** @override */
    protected requiredFields(): string[] {
        return [
            'down_response',
            'groups',
        ];
    }
}
