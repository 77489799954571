<item-full-modal
    modalTitle="{{ (editable.id ? l10nKeys.modalTitleEdit : l10nKeys.modalTitleCreate) | vtranslate }}"
    [item]="editable"
    [form]="form"
    (onSubmit)="submit()"
    class="gslb-service-modal"
>
    <form
        clrForm
        [clrLayout]="verticalLayout"
        #form="ngForm"
    >
        <full-modal-tab-section tabTitle="{{ globalL10nKeys.generalLabel | vtranslate }}">
            <div class="full-modal-config__section">
                <h3>{{ globalL10nKeys.generalLabel | vtranslate }}</h3>
                <avi-checkbox
                    name="gslb-service-enabled"
                    objectType="{{ objectType.GslbService }}"
                    fieldName="enabled"
                    [(ngModel)]="editable.config.enabled"
                >
                    {{ l10nKeys.enableGslbServiceCheckboxLabel | vtranslate }}
                </avi-checkbox>

                <avi-input-container>
                    <label
                        aviLabelWithTooltip
                        objectType="{{ objectType.GslbService }}"
                        fieldName="name"
                        for="name"
                        required
                    >
                        {{ globalL10nKeys.nameLabel | vtranslate }}
                    </label>
                    <input
                        id="name"
                        name="name"
                        type="text"
                        [(ngModel)]="editable.config.name"
                        clrInput
                        stringLengthInputValidation
                        objectType="{{ objectType.GslbService }}"
                        fieldName="name"
                        placeholder="{{ globalL10nKeys.namePlaceholder | vtranslate }}"
                        regexPattern="objName"
                        required
                    />
                </avi-input-container>

                <avi-textarea-container>
                    <label
                        aviLabelWithTooltip
                        for="description"
                        objectType="{{ objectType.GslbService }}"
                        fieldName="description"
                    >
                        {{ globalL10nKeys.descriptionLabel | vtranslate }}
                    </label>
                    <textarea
                        clrTextarea
                        id="description"
                        [(ngModel)]="editable.config.description"
                        name="description"
                        placeholder="{{ globalL10nKeys.descriptionPlaceholder | vtranslate }}"
                    ></textarea>
                </avi-textarea-container>

                <avi-input-container>
                    <label
                        aviLabelWithTooltip
                        objectType="{{ objectType.GslbService }}"
                        fieldName="pool_algorithm"
                        for="pool-algorithm"
                        required
                    >
                        {{ l10nKeys.groupsLoadBalancingAlgorithmDropdownLabel | vtranslate }}
                    </label>
                    <avi-enum-dropdown
                        name="pool-algorithm"
                        enum="GslbServiceAlgorithm"
                        [(ngModel)]="editable.config.pool_algorithm"
                        required
                    ></avi-enum-dropdown>
                </avi-input-container>

                <avi-checkbox
                    name="topology-policy-enabled"
                    objectType="{{ objectType.GslbService }}"
                    fieldName="topology_policy_enabled"
                    [(ngModel)]="editable.config.topology_policy_enabled"
                >
                    {{ l10nKeys.topologyPolicyEnabledCheckboxLabel | vtranslate }}
                </avi-checkbox>

                <avi-checkbox
                    name="resolve-cname"
                    objectType="{{ objectType.GslbService }}"
                    fieldName="resolve_cname"
                    [(ngModel)]="editable.config.resolve_cname"
                >
                    {{ l10nKeys.resolveCnameCheckboxLabel | vtranslate }}
                </avi-checkbox>

                <div class="full-modal-config__form-control-row">
                    <div class="full-modal-config__form-control">
                        <avi-fieldset [hideContent]="!editable.config.site_persistence_enabled">
                            <avi-fieldset_header>
                                <avi-checkbox
                                    [noMarginTop]="true"
                                    name="site-persistence-enabled"
                                    objectType="{{ objectType.GslbService }}"
                                    fieldName="site_persistence_enabled"
                                    [(ngModel)]="editable.config.site_persistence_enabled"
                                    (ngModelChange)="handleSitePersistenceChange()"
                                >
                                    {{ l10nKeys.sitePersistenceEnabledCheckboxLabel | vtranslate }}
                                </avi-checkbox>
                            </avi-fieldset_header>
                            <avi-fieldset_content>
                                <div class="full-modal-config__form-control-row gslb-service-modal__no-margin-top">
                                    <div class="full-modal-config__form-control">
                                        <label
                                            aviLabelWithTooltip
                                            fieldName="application_persistence_profile_uuid"
                                            for="application-persistence-profile-uuid"
                                            objectType="{{ objectType.GslbService }}"
                                        >
                                            {{ l10nKeys.persistenceProfileDropdownLabel | vtranslate }}
                                        </label>
                                        <avi-collection-dropdown
                                            [collection]="persistenceProfileCollection"
                                            [(ngModel)]="editable.config.application_persistence_profile_ref"
                                            placeholder="{{ l10nKeys.persistenceProfileDropdownPlaceholder | vtranslate }}"
                                            name="application-persistence-profile-uuid"
                                        ></avi-collection-dropdown>
                                    </div>
                                </div>
                                <div class="full-modal-config__form-control-row">
                                    <div class="full-modal-config__form-control">
                                        <label
                                            aviLabelWithTooltip
                                            fieldName="pki_profile_uuid"
                                            for="pki-profile-uuid"
                                            objectType="{{ objectType.GslbService }}"
                                        >
                                            {{ l10nKeys.pkiProfileDropdownLabel | vtranslate }}
                                        </label>
                                        <avi-collection-dropdown
                                            [collection]="pkiProfileCollection"
                                            [(ngModel)]="editable.config.pki_profile_ref"
                                            placeholder="{{ l10nKeys.pkiProfileDropdownPlaceholder | vtranslate }}"
                                            name="pki-profile-uuid"
                                        ></avi-collection-dropdown>
                                    </div>
                                </div>
                            </avi-fieldset_content>
                        </avi-fieldset>
                    </div>
                </div>

                <avi-checkbox
                    name="wildcard-match"
                    objectType="{{ objectType.GslbService }}"
                    fieldName="wildcard_match"
                    [(ngModel)]="editable.config.wildcard_match"
                >
                    {{ l10nKeys.wildcardMatchCheckboxLabel | vtranslate }}
                </avi-checkbox>

                <avi-checkbox
                    name="edns-client-subnet"
                    objectType="{{ objectType.GslbService }}"
                    fieldName="use_edns_client_subnet"
                    [(ngModel)]="editable.config.use_edns_client_subnet"
                >
                    {{ l10nKeys.ednsClientSubnetCheckboxLabel | vtranslate }}
                </avi-checkbox>

                <div  class="full-modal-config__form-control-row">
                    <div class="full-modal-config__form-control">
                        <avi-input-container noMarginTop>
                            <label
                                aviLabelWithTooltip
                                objectType="{{ objectType.GslbService }}"
                                fieldName="num_dns_ip"
                                for="num-dns-ip"
                            >
                                {{ l10nKeys.ipsReturnedByDnsInputLabel | vtranslate }}
                            </label>
                            <input
                                name="num-dns-ip"
                                id="num-dns-ip"
                                type="number"
                                configFieldInputValidation
                                objectType="{{ objectType.GslbService }}"
                                fieldName="num_dns_ip"
                                clrInput
                                placeholder="{{ l10nKeys.defaultDnsServiceInputPlaceholder | vtranslate }}"
                                [(ngModel)]="editable.config.num_dns_ip"
                            />
                        </avi-input-container>
                    </div>

                    <div class="full-modal-config__form-control">
                        <avi-input-container
                            helperText="{{ globalL10nKeys.secondsLabel | vtranslate }}"
                            noMarginTop
                        >
                            <label
                                aviLabelWithTooltip
                                objectType="{{ objectType.GslbService }}"
                                fieldName="ttl"
                                for="ttl"
                            >
                                {{ l10nKeys.ttlServedByDnsServiceInputLabel | vtranslate }}
                            </label>
                            <input
                                name="ttl"
                                id="ttl"
                                type="number"
                                configFieldInputValidation
                                objectType="{{ objectType.GslbService }}"
                                fieldName="ttl"
                                clrInput
                                placeholder="{{ l10nKeys.defaultDnsServiceInputPlaceholder | vtranslate }}"
                                [(ngModel)]="editable.config.ttl"
                            />
                        </avi-input-container>
                    </div>
                </div>

                <avi-input-container>
                    <label
                        aviLabelWithTooltip
                        objectType="{{ objectType.GslbService }}"
                        fieldName="min_members"
                        for="min-members"
                    >
                        {{ l10nKeys.minimumServersInputLabel | vtranslate }}
                    </label>
                    <input
                        name="min-members"
                        id="min-members"
                        type="number"
                        configFieldInputValidation
                        objectType="{{ objectType.GslbService }}"
                        fieldName="min_members"
                        clrInput
                        placeholder="{{ l10nKeys.minimumServersInputPlaceholder | vtranslate }}"
                        [(ngModel)]="editable.config.min_members"
                    />
                </avi-input-container>

                <div class="full-modal-config__form-control-row">
                    <div class="full-modal-config__form-control">
                        <avi-fieldset [hideContent]="!showFallbackSettings">
                            <avi-fieldset_header class="gslb-service-modal__avi-fieldset_header">
                                <avi-input-container noMarginTop>
                                    <label
                                        aviLabelWithTooltip
                                        objectType="{{ objectType.GslbServiceDownResponse }}"
                                        fieldName="type"
                                        for="down-response-type"
                                        required
                                    >
                                        {{ l10nKeys.downResponseTypeInputLabel | vtranslate }}
                                    </label>
                                    <avi-enum-dropdown
                                        name="down-response-type"
                                        enum="GslbServiceDownResponseType"
                                        [(ngModel)]="editable.config.down_response.config.type"
                                        required
                                    ></avi-enum-dropdown>
                                </avi-input-container>
                            </avi-fieldset_header>
                            <avi-fieldset_content *ngIf="showFallbackSettings">
                                <ng-container *ngIf="isFallbackIpDownResponse">
                                    <div class="full-modal-config__form-control-row gslb-service-modal__no-margin-top">
                                        <div class="full-modal-config__form-control">
                                            <avi-input-container noMarginTop>
                                                <label
                                                    aviLabelWithTooltip
                                                    objectType="{{ objectType.GslbServiceDownResponse }}"
                                                    fieldName="fallback_ip"
                                                    for="fallback-ip"
                                                    [required]="!fallbackIp6Address"
                                                >
                                                    {{ globalL10nKeys.ipv4AddressInputLabel | vtranslate }}
                                                </label>
                                                <input
                                                    id="fallback-ip"
                                                    name="fallback-ip"
                                                    type="text"
                                                    [(ngModel)]="editable.config.down_response.config.fallback_ip.address"
                                                    regexPattern="ip"
                                                    placeholder="{{ globalL10nKeys.ipv4AddressPlaceholderLabel | vtranslate }}"
                                                    [required]="!fallbackIp6Address"
                                                    clrInput
                                                />
                                            </avi-input-container>
                                        </div>
                                        <div class="full-modal-config__form-control">
                                            <avi-input-container noMarginTop>
                                                <label
                                                    aviLabelWithTooltip
                                                    objectType="{{ objectType.GslbServiceDownResponse }}"
                                                    fieldName="fallback_ip6"
                                                    for="fallback-ip6"
                                                    [required]="!fallbackIpAddress"
                                                >
                                                    {{ globalL10nKeys.ipv6AddressInputLabel | vtranslate }}
                                                </label>
                                                <input
                                                    id="fallback-ip6"
                                                    name="fallback-ip6"
                                                    type="text"
                                                    [(ngModel)]="editable.config.down_response.config.fallback_ip6.address"
                                                    regexPattern="ipv6"
                                                    placeholder="{{ globalL10nKeys.ipv6AddressPlaceholderLabel | vtranslate }}"
                                                    [required]="!fallbackIpAddress"
                                                    clrInput
                                                />
                                            </avi-input-container>
                                        </div>
                                    </div>
                                </ng-container>
                                <ng-container *ngIf="isFallbackCnameDownResponse">
                                    <avi-input-container noMarginTop>
                                        <label
                                            aviLabelWithTooltip
                                            objectType="{{ objectType.GslbServiceDownResponse }}"
                                            fieldName="fallback_cname"
                                            for="fallback-cname"
                                            required
                                        >
                                            {{ globalL10nKeys.fqdnLabel | vtranslate }}
                                        </label>
                                        <input
                                            id="fallback-cname"
                                            name="fallback-cname"
                                            type="text"
                                            [(ngModel)]="editable.config.down_response.config.fallback_cname"
                                            regexPattern="fqdn"
                                            placeholder="{{ globalL10nKeys.fqdnPlaceholder | vtranslate }}"
                                            required
                                            clrInput
                                        />
                                    </avi-input-container>
                                </ng-container>
                            </avi-fieldset_content>
                        </avi-fieldset>
                    </div>
                </div>

                <gslb-service-health-monitors-grid [editable]="editable"></gslb-service-health-monitors-grid>

                <enum-radio-buttons
                    enum="GslbServiceHealthMonitorScope"
                    name="health-monitor-scope"
                    [(ngModel)]="editable.config.health_monitor_scope"
                >
                    <label
                        aviLabelWithTooltip
                        objectType="{{ objectType.GslbService }}"
                        fieldName="health_monitor_scope"
                    >
                        {{ l10nKeys.healthMonitorScopeInputLabel | vtranslate }}
                    </label>
                </enum-radio-buttons>

                <avi-checkbox
                    name="controller-health-status-enabled"
                    objectType="{{ objectType.GslbService }}"
                    fieldName="controller_health_status_enabled"
                    [(ngModel)]="editable.config.controller_health_status_enabled"
                >
                    {{ l10nKeys.controllerHealthStatusCheckboxLabel | vtranslate }}
                </avi-checkbox>
            </div>
        </full-modal-tab-section>

        <full-modal-tab-section tabTitle="{{ l10nKeys.domainSectionHeader | vtranslate }}">
            <div class="full-modal-config__section">
                <h3>{{ l10nKeys.domainSectionHeader | vtranslate }}</h3>
                <gslb-service-domain-names-grid
                    [splitDomainNames]="splitDomainNames"
                    [editable]="editable"
                ></gslb-service-domain-names-grid>
            </div>
        </full-modal-tab-section>

        <full-modal-tab-section tabTitle="{{ l10nKeys.poolSectionHeader | vtranslate }}">
            <div class="full-modal-config__section">
                <h3>{{ l10nKeys.poolSectionHeader | vtranslate }}</h3>
                <gslb-service-pools-grid
                    [pools]="editable.config.groups"
                    (onAddPool)="editable.addPool()"
                    (onEditPool)="editable.editPool($event)"
                    (onDeletePool)="editable.deletePool($event)"
                ></gslb-service-pools-grid>
            </div>
        </full-modal-tab-section>
    </form>
</item-full-modal>
