/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @module MessageItemsModule
 */

import { MessageItem } from 'ajs/modules/data-model/factories/message-item.factory';
import { GslbServiceDownResponseType, IGslbServiceDownResponse } from 'generated-types';
import { GslbServiceDownResponse } from 'object-types';
import { IpAddrConfigItem } from 'ajs/modules/data-model/factories/ip-addr.config-item.factory';

/**
 * IGslbServiceDownResponse omitting message item fields.
 */
type IGslbServiceDownResponsePartial = Omit<IGslbServiceDownResponse,
'fallback_ip' |
'fallback_ip6'
>;

/**
 * IGslbServiceDownResponse with message items.
 */
interface IGslbServiceDownResponseConfig extends IGslbServiceDownResponsePartial {
    fallback_ip?: IpAddrConfigItem,
    fallback_ip6?: IpAddrConfigItem,
}

/**
 * @description GslbServiceDownResponse config item
 * @author alextsg
 */
export class GslbServiceDownResponseConfigItem extends MessageItem<IGslbServiceDownResponseConfig> {
    constructor(args = {}) {
        const extendedArgs = {
            objectType: GslbServiceDownResponse,
            ...args,
        };

        super(extendedArgs);
    }

    /**
     * Return true if the type matches the configured down response type.
     */
    public isDownResponseType(type: GslbServiceDownResponseType): boolean {
        return this.config.type === type;
    }

    /**
     * @override
     * Remove config fields not relevant to the selected type.
     */
    protected modifyConfigDataBeforeSave(): void {
        super.modifyConfigDataBeforeSave();

        const { type } = this.config;

        if (type !== GslbServiceDownResponseType.GSLB_SERVICE_DOWN_RESPONSE_CNAME) {
            delete this.config.fallback_cname;
        }

        if (type !== GslbServiceDownResponseType.GSLB_SERVICE_DOWN_RESPONSE_FALLBACK_IP) {
            delete this.config.fallback_ip;
            delete this.config.fallback_ip6;
        }
    }

    /** @override */
    protected requiredFields(): string[] {
        return [
            'fallback_ip',
            'fallback_ip6',
        ];
    }
}
