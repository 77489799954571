/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/** @module GslbModule */

import {
    Component,
    Inject,
    Input,
    OnDestroy,
} from '@angular/core';

import {
    GSLBService,
    ISplitDomainName,
} from 'ajs/modules/gslb/factories/gslb-service/gslb-service.item.factory';

import {
    GslbService,
    GslbServiceDownResponse,
} from 'object-types';

import { ClrFormLayout } from '@clr/angular';

import {
    PersistenceProfileCollection,
    TPersistenceProfileCollection,
} from 'ajs/modules/persistence-profile/factories/persistence-profile.collection.factory';

import {
    PKIProfileCollection,
    TPkiProfileCollection,
} from 'ajs/modules/security/factories/pki-profile/pki-profile.collection.factory';

import {
    GslbServiceDownResponseType,
    PersistenceProfileType,
} from 'generated-types';

import { IEnumValue } from 'ajs/modules/core/services/schema-service/schema.types';
import './gslb-service-modal.component.less';
import { L10nService } from '@vmw/ngx-vip';
import * as globalL10n from 'global-l10n';
import * as l10n from './gslb-service-modal.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;
const { ...globalL10nKeys } = globalL10n;

/**
 * @description Component for GSLB Service Item Modal.
 * @author Suraj Kumar
 */
@Component({
    selector: 'gslb-service-modal',
    templateUrl: './gslb-service-modal.component.html',
})
export class GslbServiceModalComponent implements OnDestroy {
    @Input()
    public editable: GSLBService;

    public readonly persistenceProfileCollection: PersistenceProfileCollection;

    public readonly pkiProfileCollection: PKIProfileCollection;

    /**
     * Rows for Domain Names grid.
     */
    public splitDomainNames: ISplitDomainName[] = [];

    public readonly verticalLayout = ClrFormLayout.VERTICAL;

    public readonly l10nKeys = l10nKeys;

    public readonly globalL10nKeys = globalL10nKeys;

    /**
     * Object types for Gslb Service Modal.
     */
    public readonly objectType = {
        GslbService,
        GslbServiceDownResponse,
    };

    constructor(
        l10nService: L10nService,
        @Inject(PersistenceProfileCollection)
        PersistenceProfileCollection: TPersistenceProfileCollection,
        @Inject(PKIProfileCollection)
        PKIProfileCollection: TPkiProfileCollection,
    ) {
        const params = { is_federated: true };
        const persistenceProfileParams = {
            ...params,
            persistence_type: PersistenceProfileType.PERSISTENCE_TYPE_GSLB_SITE,
        };

        this.persistenceProfileCollection = new PersistenceProfileCollection({
            params: persistenceProfileParams,
            defaults: persistenceProfileParams,
        });

        this.pkiProfileCollection = new PKIProfileCollection({
            params,
            defaults: params,
        });

        l10nService.registerSourceBundles(dictionary);
    }

    /** @override */
    public ngOnDestroy(): void {
        this.persistenceProfileCollection.destroy();
        this.pkiProfileCollection.destroy();
    }

    /**
     * Callback to ngFor-track by.
     */
    public trackByValue(index: number, type: IEnumValue): IEnumValue['value'] {
        return type.value;
    }

    /**
     * Handle Site Persistence selection change.
     */
    public handleSitePersistenceChange(): void {
        if (!this.editable.config.site_persistence_enabled) {
            this.editable.deleteSitePersistenceConfig();
        }
    }

    /**
     * Return true if fallback settings (IP, CNAME) should be configurable.
     */
    public get showFallbackSettings(): boolean {
        return this.isFallbackCnameDownResponse || this.isFallbackIpDownResponse;
    }

    /**
     * Return true if the configured fallback is IP.
     */
    public get isFallbackIpDownResponse(): boolean {
        return this.editable.isDownResponseType(
            GslbServiceDownResponseType.GSLB_SERVICE_DOWN_RESPONSE_FALLBACK_IP,
        );
    }

    /**
     * Return true if the configured fallback is CNAME.
     */
    public get isFallbackCnameDownResponse(): boolean {
        return this.editable.isDownResponseType(
            GslbServiceDownResponseType.GSLB_SERVICE_DOWN_RESPONSE_CNAME,
        );
    }

    /**
     * Return the configured fallback IPv4.
     */
    public get fallbackIpAddress(): string | undefined {
        return this.editable.config.down_response.config.fallback_ip.address;
    }

    /**
     * Return the configured fallback IPv6.
     */
    public get fallbackIp6Address(): string | undefined {
        return this.editable.config.down_response.config.fallback_ip6.address;
    }

    /**
     * Handle GSLB Service submit.
     */
    public submit(): void {
        this.editable.updateDomainNames(this.splitDomainNames);
        this.editable.submit();
    }
}
