/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @module AnalyticsModule
 */

import {
    IClientLogStreamingConfig,
    IStreamingSyslogConfig,
} from 'generated-types';

import { ClientLogStreamingConfig } from 'object-types';
import { MessageItem } from 'ajs/modules/data-model/factories/message-item.factory';

type TClientLogStreamingConfigPartial = Omit<IClientLogStreamingConfig, 'syslog_config'>;

export interface IClientLogStreamingConfigPartial extends TClientLogStreamingConfigPartial {
    syslog_config?: MessageItem<IStreamingSyslogConfig>,
}

export class ClientLogStreamingConfigConfigItem extends
    MessageItem<IClientLogStreamingConfigPartial> {
    constructor(args = {}) {
        const extendedArgs = {
            objectType: ClientLogStreamingConfig,
            ...args,
        };

        super(extendedArgs);
    }

    /**
     * Get SyslogConfig from ClientLogStreamingConfig.
     */
    public get syslogConfigData(): IStreamingSyslogConfig {
        return this.config.syslog_config?.config;
    }

    /** @override */
    protected requiredFields(): string[] {
        return [
            'syslog_config',
        ];
    }
}
